*,
::before,
::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

label {
  font-size: 20px;
}

html,
body {
  height: 100%;
  width: 100%;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  background-color: #fff;
  padding: 32px 48px;
  border-radius: 10px;
  width: 100%;
  max-width: 400px;
  box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
}

.container > h1 {
  margin-bottom: 20px;
}

.container > form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.container div {
  display: flex;
  flex-direction: column;
}

.container input {
  width: 100%;
  font-size: 20px;
  padding: 10px;
  border: none;
  outline: none;
  border-bottom: 1px solid black;
}

.container input::placeholder {
  font-size: 12px;
  font-style: italic;
}

button {
  background-color: purple;
  border: none;
  font-size: 20px;
  color: white;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  margin: 10px 0;
}
